import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm;
  errorMessage: Boolean = true;
  message = "";
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService
  ) {

    this.loginForm = formBuilder.group({
      username: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])],
    });
    this.loginForm.valueChanges
      .subscribe(data => {

      });
  }

  ngOnInit() {
    // this.testApi();
  }



  login(formData) {
    if (this.loginForm.invalid) {
      console.log()
      this.errorMessage = false;
      if (formData.username == "" && formData.password == "") {
        this.message = "Please enter the username and password"
      } else if (formData.username == "") {
        this.message = "Please enter the username"

      } else {
        this.message = "Please enter the password"

      }
    } else {
      let apiUrl = this.apiConfig.login;
      let body = JSON.stringify({ "LoginForm": { "username": formData.username, "password": formData.password } });
      this.apiService.post(apiUrl, body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.errorMessage = true;
            let body = res.body;
            this.toast.Success('Login successful');
            localStorage.setItem("user_name", (res.body.data.user_name));
            localStorage.setItem("user_id", (res.body.data.id));
            this.auth.saveAuth(body.data.access_token);
            if (body.data.user_role == 1) {
              this.router.navigate(["hubAdmin"]);
            }
            else if (body.data.user_role == 8) {
              this.router.navigate(["teamleader"]);
              // this.router.navigate(["reporting-dashboard"]);
            }
            else if (body.data.user_role == 9) {
              this.router.navigate(["agent"]);
            }
            else if (body.data.user_role == 14) {
              // this.router.navigate(["teamleader"]);
              this.router.navigate(["reporting-dashboard"]);
            }
            else {
              this.toast.Success('You dont have access to this module');
            }
          }
        }, err => {
          this.errorMessage = false;
          // console.log('err', err);
        })
    }

  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }
}


// testApi(){
//   this.apiService.get(this.apiConfig.baseUrl2)
//   .subscribe((res:any)=>{
//     console.log('success', res)
//   },err=>{
//     console.log('failed', err);
//   })
// }
